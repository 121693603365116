<template>
  <div class="background-light about-container">
    <div class="about wrapper">
      <div id="about-1" class="about-text">
        <h1 class="h1-small">{{ $t("message.about.head_1") }}</h1>
        <p v-html="$t('message.about.text_1')"></p>
      </div>
      <div id="about-2" class="about-text">
        <h1 class="h1-small">{{ $t("message.about.head_2") }}</h1>
        <p v-html="$t('message.about.text_2')"></p>
      </div>
      <div id="about-3" class="about-text">
        <h1 class="h1-big">{{ $t("message.about.head_3") }}</h1>
        <p v-html="$t('message.about.text_3')"></p>
      </div>
      <div id="about-4" class="about-text">
        <h1 class="h1-small">{{ $t("message.about.head_4") }}</h1>
        <p v-html="$t('message.about.text_4')"></p>
      </div>
    </div>
    <contact />
  </div>
</template>
<script>
import Contact from "@/components/Home/Contact.vue";
export default {
  name: "about",
  components: {
    Contact
  },
  props: ["lang"],
  mounted() {
    if (this.lang) {
      this.$i18n.locale = this.lang;
    }
  }
};
</script>
<style lang="scss">
.about-container {
  @media (min-width: 1024px) {
    background-image: url("../assets/images/image1_476.jpg"),
      url("../assets/images/image2_426.jpg"),
      url("../assets/images/image3_476.jpg"),
      url("../assets/images/image4_426.jpg");
    background-repeat: no-repeat;
    background-position: right top, left 1116px, right 1884px, left 2942px;
  }
  @media (min-width: 1440px) {
    background-image: url("../assets/images/image1_756.jpg"),
      url("../assets/images/image2_546.jpg"),
      url("../assets/images/image3_756.jpg"),
      url("../assets/images/image4_546.jpg"),
      url("../assets/images/pattern0.png"),
      url("../assets/images/pattern-about-right-1.png"),
      url("../assets/images/pattern-about-right-2.png");
    background-position: right 0px, left 1116px, right 1884px, left 2942px,
      left 0px top 407px, right 1225px, right 1425px;
  }

  .about {
    .about-text {
      text-align: left;
    }
    @media (min-width: 300px) {
      #about-1 {
        grid-column: col-start 1 / span 4;
        grid-row: 1 / span 1;
      }
      #about-2 {
        grid-column: col-start 1 / span 4;
        grid-row: 2 / span 1;
      }
      #about-3 {
        grid-column: col-start 1 / span 6;
        grid-row: 3 / span 1;
      }
      #about-4 {
        grid-column: col-start 1 / span 6;
        grid-row: 4 / span 1;
      }
    }
    @media (min-width: 768px) {
      #about-1 {
        grid-column: col-start 2 / span 6;
        grid-row: 1 / span 1;
      }
      #about-2 {
        grid-column: col-start 2 / span 6;
        grid-row: 2 / span 1;
      }
      #about-3 {
        grid-column: col-start 2 / span 6;
        grid-row: 3 / span 1;
      }
      #about-4 {
        grid-column: col-start 2 / span 6;
        grid-row: 4 / span 1;
      }
    }
    @media (min-width: 1024px) {
      #about-1 {
        height: 870px;
        padding-left: 30px;
        padding-top: 230px;
        grid-column: col-start 1 / span 5;
        grid-row: 1 / span 1;
      }
      #about-2 {
        height: 756px;
        padding-top: 269px;
        grid-column: col-start 6 / span 5;
        grid-row: 2 / span 1;
      }
      #about-3 {
        height: 1077px;
        padding-top: 367px;
        grid-column: col-start 1 / span 4;
        grid-row: 3 / span 1;
      }
      #about-4 {
        height: 933px;
        padding-top: 269px;
        grid-column: col-start 6 / span 5;
        grid-row: 4 / span 1;
      }
    }
  }
}
</style>
